<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col md="6">
        <b-form-group label="Raw Material Name" label-for="mc-Raw Material-name">
          <b-form-input type="text" id="mc-Raw Material-name" placeholder="Raw Material Name"
            v-model="rawMaterial.name" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Raw Material Code" label-for="mc-Raw Material-Code">
          <b-form-input type="text" id="mc-Raw Material-Code" placeholder="Raw Material Code"
            v-model="rawMaterial.code" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Low Stock Threshold">
          <div class="form-label-group">
            <b-form-input id="mc-stock" type="text" placeholder="Low Stock Threshold" v-model="rawMaterial.lowStock" />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Unit of Measure (UOM)">
          <b-form-select v-model="rawMaterial.defaultUomID" :options="itemUoms" value-field="id" text-field="name" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Price (Rs)" label-for="Price (Rs)">
          <b-form-input type="text" id="mc-Raw Material-Price" placeholder="Raw Material Price"
            v-model="rawMaterial.purchasePrice" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Category" label-for="mc-Category">
          <b-form-select v-model="rawMaterial.categoryID" :options="itemCategories" value-field="id" text-field="name" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Preferred Supplier" label-for="mc-Supplier">

          <b-form-select v-model="rawMaterial.supplierId" :options="suppliers" value-field="id" text-field="name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" class="mb-2">
        <label for="textarea-default">Raw Material Description</label>
        <b-form-textarea id="textarea-default" placeholder="Raw Material Description" rows="5"
          v-model="rawMaterial.description" />
      </b-col>
      <!-- submit and reset -->
      <b-col>
        <b-button type="submit" variant="primary" @click="updateRawMaterial()"><b-spinner small v-if="loading"
            class="mx-2" />Save Changes</b-button>&nbsp;
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormGroup,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddRawMaterial",
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BSpinner,
  },
  methods: {
    success() {
      this.$swal({
        title: "RawMaterial!",
        text: "You have successfully edited the raw material!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    error() {
      this.$swal({
        title: "Error!",
        text: " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    ...mapActions("rawmaterialModule", [
      "getRawMaterialListByIdAction",
      "updateRawMaterialAction",
    ]),
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    ...mapActions("itemcategoryModule", ["getItemCategoryListAction"]),
    updateRawMaterial() {
      let payload = {
        id: this.rawMaterial.id,
        name: this.rawMaterial.name,
        description: this.rawMaterial.description,
        categoryID: this.rawMaterial.categoryID,
        code: this.rawMaterial.code,
        supplierId: this.rawMaterial.supplierId,
        lowStock: this.rawMaterial.lowStock,
        defaultUomID: this.rawMaterial.defaultUomID,
        purchasePrice: this.rawMaterial.purchasePrice,
      };
      this.updateRawMaterialAction(payload)
        .then(() => {
          this.success();
          this.$router.push({ name: "rawmaterials-list" });
        })
        .catch((error) => {
          this.error();
        });
    },
  },
  computed: {
    ...mapGetters("rawmaterialModule", {
      rawMaterial: "rawmaterialsID",
      loading: "loading",
    }),
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      loading: "loading",
    }),
    ...mapGetters("itemcategoryModule", {
      itemCategories: "itemcategories",
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {
      suppliers: "suppliers",
      loading: "loading"
    })

  },
  async mounted() {
    await this.getRawMaterialListByIdAction(this.$route.params.id);
    await this.getItemUOMListAction();
    await this.getItemCategoryListAction();
  },
};
</script>
